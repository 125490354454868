import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404"
      description="You just hit a route that doesn&#39;t exist... the sadness." />
    <center><h1 style={{ marginTop: "4.1rem" }}>NOT FOUND</h1></center>
    <center> <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <Link to='/'>Back to Home</Link></center>

    <p style={{ padding: "2rem" }}></p>
  </Layout>
)


export default NotFoundPage
